<template>

    <div id="loan-detail" v-if="isMounted">
        <div class="vx-row">
            <!-- header -->
            <div class="vx-col w-full mb-base">
                <vx-card>
                    <!-- header -->
                    <div class="vx-row">
                        <!-- logo -->
                        <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 mb-4">
                            <div class="con-example-images mb-3">
                                <template v-if="financeLoanData.project.webinar != null">
                                    <youtube-video-widget :media="webinarInfo" title="¿Cómo llenar la solicitud?" cWidth="100%" cHeight="15rem"/>
                                </template>
                                <template v-else>
                                    <img class="fix-logo" :src="getUrlLogo" alt="Logo">
                                </template>
                            </div>
                            <vs-button v-if="financeLoanData.project.pitch_file" type="border" class="w-full mt-3 mb-4" @click.stop="goToNewContent(financeLoanData.project.pitch_file)" icon-pack="feather" icon="icon-file-text" >Presentación ejecutiva </vs-button>
                            <div class="vx-row justify-center">
                                <div v-for="(ods, ODSIndex) in getOds.pre_set" class="vx-col mb-2">
                                    <img :src="getCurrentOds(ods)" :alt="`ODS-${ods}`" :key="ODSIndex" class="fix-ods-header">
                                </div> 
                            </div> 
                        </div>
                        <!-- project-info -->
                        <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-4">
                            <h1 class="extrabold-title mb-4">{{publicProjectData.project.alias}}</h1>
                            <div id="info-chips" class="flex mb-4">
                                <div @click.stop="goToNewContent(financeLoanData.promo.promo_link)">
                                    <vs-chip v-if="financeLoanData.promo.promo_link != null" class="mr-3" color="primary">{{ financeLoanData.promo.promo_title || "Promoción" }}</vs-chip>
                                </div>
                                <vs-chip class="mr-3" color="#d6f7fb">
                                    <span style="color: #00cfe8;">{{ financeLoanData.project.project_category ? translateStrategy(financeLoanData.project.project_category.strategy) : "No definido" }}</span>
                                </vs-chip>
                                <vs-chip color="rgba(115, 103, 240, 0.16)" style="color:#7367f0;">{{ financeLoanData.project.project_category ? financeLoanData.project.project_category.name : "No definido" }}</vs-chip>
                            </div>
                            <p class="black mb-4 text-pre-wrap">
                                {{publicProjectData.project.description}}
                            </p>
                            <div v-if="isMoral" class="flex">
                                <a
                                    v-if="applicantLoanData.website != null"
                                    :href="$sanitizeUrl(applicantLoanData.website)"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <feather-icon icon="GlobeIcon" class=" text-dark" />
                                </a>
                                <a
                                    v-if="calculateSocialNetwork('Facebook').url != ''"
                                    :href="$sanitizeUrl(calculateSocialNetwork('Facebook').url)"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <feather-icon :icon="calculateSocialNetwork('Facebook').icon" class="inline-social-icon text-dark" />
                                </a>
                                <a
                                    v-if="calculateSocialNetwork('Twitter').url != ''"
                                    :href="$sanitizeUrl(calculateSocialNetwork('Twitter').url)"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <feather-icon :icon="calculateSocialNetwork('Twitter').icon" class="inline-social-icon text-dark" />
                                </a>
                                <a
                                    v-if="calculateSocialNetwork('Instagram').url != ''"
                                    :href="$sanitizeUrl(calculateSocialNetwork('Instagram').url)"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <feather-icon :icon="calculateSocialNetwork('Instagram').icon" class="inline-social-icon text-dark" />
                                </a>
                                <a
                                    v-if="calculateSocialNetwork('LinkedIn').url != ''"
                                    :href="$sanitizeUrl(calculateSocialNetwork('LinkedIn').url)"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <feather-icon :icon="calculateSocialNetwork('LinkedIn').icon" class="inline-social-icon text-dark" />
                                </a>
                            </div>
                            <!-- <vs-button @click.stop="goToOportunities()">Ir a invertir</vs-button> -->
                        </div>
                    </div>
                    <vs-divider class="mb-4"></vs-divider>
                    <!-- stats -->
                    <div class="vx-row mb-4">
                        <div class="vx-col sm:w-1/2 md:w-2/6 lg:w-2/12 align-left">
                            <span>Monto a financiar</span>
                            <br>
                            <span class="bold">{{explicitMoneyFormat(financeLoanData.financed_amount, 0)}}</span>
                        </div>
                        <div class="vx-col sm:w-1/2 md:w-2/6 lg:w-2/12 align-left">
                            <span>Faltantes</span>
                            <br>
                            <span class="bold">{{explicitMoneyFormat(financeLoanData.financed_amount - financeLoanData.invested, 0) }}</span>
                        </div>
                        <div class="vx-col sm:w-1/2 md:w-2/6 lg:w-1/12 align-left">
                            <span>Tasa</span>
                            <br>
                            <span class="bold">{{(financeLoanData.interest_rate * 100).toFixed(2)}}%</span>
                        </div>
                        <div class="vx-col sm:w-1/2 md:w-2/6 lg:w-2/12 align-left">
                            <span>Inversionistas</span>
                            <br>
                            <span class="bold">{{format_price(publicProjectData.project.inversionistas)}}</span>
                        </div>
                        <div class="vx-col sm:w-1/2 md:w-2/6 lg:w-3/12 align-left">
                            <span>Avance en fondeo</span>
                            <br>
                            <div class="flex">
                                <span class="bold">{{investedPercentage.toFixed(0)}}%</span> 
                                <vs-progress class="mt-2 ml-3" :percent="investedPercentage" :height="10" color="primary"></vs-progress>
                            </div>
                        </div>
                        <div class="vx-col sm:w-1/2 md:w-2/6 lg:w-2/12 align-left">
                            <vs-button @click.stop="goToOportunities()">Ir a invertir</vs-button>
                        </div>
                    </div>
                </vx-card>
            </div>

            <!-- Galería -->
            <div v-if="isMoral" class="vx-col w-full mb-base">
                <swiper :options="swiperOptions" class="swiper" ref="swiperTop"
                        @slideChangeTransitionStart="handleSwiperSlideChangeTransitionStart">
                    <swiper-slide v-for="(img, idx) in images" :key="idx" class="img-slider" >
                        <vue-load-image class="horizontal-alignment">
                            <img slot="image" class="img-responsive rounded-top-img" :src="img.url" alt="banner">
                            <img slot="preloader" :src="loader" alt="loader" />
                            <img slot="error" :src="placeholder" alt="placeholder" class="img-responsive rounded-top-img" />
                        </vue-load-image>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>

            <!-- INFO PROMO -->
            <div v-if="getPromoUrl" class="vx-col w-full mb-base">
                <vx-card id="promo-card">
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-1/3 lg:w1/3">
                            <img v-if="getPromoUrl != null" :src="getPromoUrl" alt="ImagenPromocion" class="fix-logo-promo">
                        </div>
                        <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 promo-padding">
                            <h3 class="extrabold-title mt-3 mb-3">{{ financeLoanData.promo.promo_title || "Promoción" }}</h3>
                            <p class="mb-3">{{ financeLoanData.promo.promo_description }}</p>
                             <vs-button class="mb-3" @click.stop="goToNewContent(financeLoanData.promo.promo_link)">Conocer más</vs-button>
                        </div>
                    </div>
                </vx-card>
            </div>

            <!-- tabs -->
            <div class="vx-col w-full mb-base">
                <vx-card id="card-tabs">
                    <vs-tabs v-model="activeTab" class="tabs-shadow-none">
                    <!-- financiamiento -->
                        <vs-tab label="Financiamiento">
                            <div class="flex ml-3">
                                <feather-icon icon="FolderIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Sobre el financiamiento</h3>
                            </div>
                            <div class="vx-row m-5">
                                <br>
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
                                    <ul class="list-bold">
                                        <li>
                                            Tipo de crédito: <span>{{translateLoanType(financeLoanData.crowdfunding_loan_type)}}</span>
                                        </li>
                                        <li>
                                            Monto a financiar: <span>{{explicitMoneyFormat(financeLoanData.financed_amount,0)}}</span>
                                        </li>
                                        <li>
                                            Plazo: <span>{{financeLoanData.loan_term}} meses</span>
                                        </li>
                                        <li>
                                            Días máximos de fondeo: <span>{{financeLoanData.max_funding_days}}</span>
                                        </li>
                                        <li>
                                            Días transcurridos en fondeo: <span>{{financeLoanData.days_in_funding}}</span>
                                        </li>
                                        <li>
                                            Tasa de interés: <span>{{(financeLoanData.interest_rate * 100).toFixed(2)}}%</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
                                    <ul class="list-bold">
                                        <li>
                                            Enganche: <span>
                                                {{ financeLoanData.loan_type == 1 ? (financeLoanData.deposit_percentage * 100).toFixed(0) + '%' : "No aplica" }}                                  
                                            </span>
                                        </li>
                                        <li>
                                            Garantías: <span>
                                                <ul class="guarantees-list">
                                                    <li v-for="guarantee in financeLoanData.loan_guarantees">{{translateGuaranteeType(guarantee.type)}} ({{guarantee.name}})</li>
                                                </ul>                                               
                                            </span>
                                        </li>
                                        <li>
                                            <div @click="goToProjectsQualificationBlog" class="flex mt-2">
                                                Calificación crediticia:<span>&nbsp;{{financeLoanData.loan_qualification}}</span>
                                                &nbsp;
                                                <vx-tooltip
                                                    title=""
                                                    color="primary"
                                                    style="float:right;cursor:pointer;margin-top:-8px"
                                                    text="¿Cómo calificamos los proyectos semilla?">
                                                    <vs-chip color="primary">?</vs-chip>
                                                </vx-tooltip>
                                            </div>
                                            <span><small>La calificación crediticia corresponde al nivel de riesgo de cada proyecto: riesgo mínimo <b>(A)</b>, riesgo muy bajo  <b>(B)</b>, riesgo bajo <b>(C)</b>, riesgo moderado <b>(D)</b>, riesgo medio alto <b>(E)</b>.</small></span>
                                        </li>
                                        <li>
                                            Participación de RedGirasol: <span>{{(financeLoanData.rg_investment * 100).toFixed(0)}}%</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="vx-col w-full">
                                    <vs-divider></vs-divider>
                                    <vs-collapse>
                                        <vs-collapse-item v-if="isMoral && financeLoanData.investment_opportunities" :open="true">
                                            <div class="black" slot="header">
                                                Puntos clave de la oportunidad
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ setBreakPoints(financeLoanData.investment_opportunities) || "NA"}}
                                            </div>
                                        </vs-collapse-item>
                                        <vs-collapse-item>
                                            <div class="black" slot="header">
                                                Plan de pagos
                                            </div>
                                            <div class="vx-col w-full">
                                                <div v-if="canAskForRefund" class="vx-col lg:w-3/4 w-full mb-4">
                                                    Las fechas del plan de pagos durante la etapa de fondeo son estimados y podrían variar al iniciar
                                                    el proceso de instalación. Para un reembolso de tus fondos, da clic
                                                    <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="onRefundClick">aquí.</router-link></span>
                                                </div>
                                                <vs-table id="payments-table" :data="financeLoanData.payment_plan" stripe noDataText="No hay registros disponibles">
                                                    <template slot="thead">
                                                        <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                                    </template>
                
                                                    <template slot-scope="{data}">
                                                        <tbody>
                                                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                            <vs-td>
                                                                {{tr.number}}
                                                            </vs-td>
                                                            <vs-td>
                                                                {{tr.payment_date}}
                                                            </vs-td>
                                                            <vs-td>${{format_price(tr.payment)}}</vs-td>
                                                            <vs-td>${{format_price(tr.initial_debt)}}</vs-td>
                                                            <vs-td>${{format_price(tr.amortization)}}</vs-td>
                                                            <vs-td>${{format_price(tr.interest)}}</vs-td>
                                                            <vs-td>${{format_price(tr.tax)}}</vs-td>
                                                            <vs-td>${{format_price(tr.final_debt)}}</vs-td>
                                                        </vs-tr>
                                                        </tbody>
                                                    </template>
                                                </vs-table>
                                           </div>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                            </div>
                        </vs-tab>
                    <!-- solicitante -->
                        <vs-tab label="Solicitante">
                            <div class="flex ml-3">
                                <feather-icon icon="SmileIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Sobre el solicitante</h3>
                            </div>
                            <!-- SOLICITANTE PM -->
                            <div v-if="isMoral" class="vx-row m-5">
                                <!-- resumen -->
                                <div  class="vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-2">
                                    <ul class="list-bold">
                                        <li>
                                            Nombre: <span>{{applicantLoanData ? applicantLoanData.name : "No definido"}}</span>
                                        </li>
                                        <li>
                                            Giro del negocio: <span>{{ isMoral ? applicantLoanData.business_activities : applicantLoanData.business_activities.name }} </span>
                                        </li>
                                        <!-- <li>
                                            Tipo: <span>{{applicantLoanData ? translateFiscalBusinessType(applicantLoanData.fiscal_business_type) : "No definido"}}</span>
                                        </li> -->
                                        <li>
                                            Fecha de constitución: <span>{{applicantLoanData.constitution_date ? constitutionDateFormat(applicantLoanData.constitution_date) : "NA"}}</span>
                                        </li>
                                        <li v-if="applicantLoanData.other_projects > 0">
                                            Cantidad de créditos previos: <span>{{applicantLoanData.other_projects}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-2">
                                    <youtube-video-widget v-if="applicantLoanData.institutional_video != null" :media="videoStp" cWidth="28rem" cHeight="15rem"/>
                                </div>
                                <vs-divider></vs-divider>
                                <!-- dirección -->
                                <div class="vx-col w-full mb-2">
                                    <ul class="list-bold">
                                        <li>
                                            <feather-icon icon="MapPinIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                             Ubicación del negocio: <span>{{applicantLoanData.address}}</span>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <vs-divider></vs-divider>
                                <!-- información adicional -->
                                <div v-if="isMoral" class="vx-col w-full">
                                    <vs-collapse>
                                        <vs-collapse-item v-if="applicantLoanData.business_model" :open="true">
                                            <div class="black" slot="header">
                                                Modelo de negocio
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ applicantLoanData.business_model || "NA" }}
                                            </div>
                                        </vs-collapse-item>
                                        <vs-collapse-item v-if="applicantLoanData.distribution_channels" :open="true">
                                            <div class="black" slot="header">
                                                Canales de distribución
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ applicantLoanData.distribution_channels || "NA" }}
                                            </div>
                                        </vs-collapse-item>
                                        <vs-collapse-item v-if="applicantLoanData.team_experience" :open="true">
                                            <div class="black" slot="header">
                                                Experiencia del Equipo
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ applicantLoanData.team_experience || "NA" }}
                                            </div>
                                        </vs-collapse-item>
                                        <vs-collapse-item v-if="applicantLoanData.startup" :open="true">
                                            <div class="black" slot="header">
                                                Antecedentes de la empresa
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ applicantLoanData.startup || "NA" }}
                                            </div>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                            </div>
                            <!-- SOLICITANTE PF -->
                            <div v-else class="vx-row m-5">
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-2">
                                    <ul class="list-bold">
                                        <li>
                                            Edad: <span>{{ applicantLoanData.age }} años</span>
                                        </li>
                                        <li>
                                            Género: <span>{{ applicantLoanData.gender == "H" ? "Hombre" : "Mujer"}}</span>
                                        </li>
                                        <li>
                                            Residencia: <span>{{ applicantLoanData.address }}</span>
                                        </li>
                                        <li>
                                            Educación: <span>{{ applicantLoanData.education_level }}</span>
                                        </li>
                                        <li>
                                            Experiencia personal o de negocios: <span>{{ applicantLoanData.ocupation.name }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-2">
                                    <ul class="list-bold">
                                        <li>
                                            Ingresos estimados: <span>{{ applicantLoanData.payment_information != null ? explicitMoneyFormat(applicantLoanData.payment_information.average_amount) : "No definido" }} </span>
                                        </li>
                                        <li>
                                            Fuentes de ingreso:
                                            <span>
                                                <ul class="guarantees-list">
                                                    <li v-for="incomeSource in applicantLoanData.payment_information.income_source_proofs">{{incomeSource.income_source_name}}.</li>
                                                </ul> 
                                            </span>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>

                            <div class="vx-row m-5">
                                <div v-if="applicantLoanData.guarantee != null" class="vx-col w-full">
                                    <vs-divider></vs-divider>
                                    <vs-collapse>
                                        <vs-collapse-item :open="true">
                                            <div class="black" slot="header">
                                                Obligado Solidario
                                            </div>
                                            <div class="vx-col w-full">
                                                <ul class="list-bold">
                                                    <li>
                                                        Relación con el solicitante: <span>{{ applicantLoanData.guarantee.relation_with_applicant }} </span>
                                                    </li>
                                                    <li>
                                                        Edad: <span>{{ applicantLoanData.guarantee.age }} años</span>
                                                    </li>
                                                    <li>
                                                        Género: <span>{{ applicantLoanData.guarantee.gender == "H" ? "Hombre" : "Mujer"}}</span>
                                                    </li>
                                                    <li>
                                                        Residencia: <span>{{ applicantLoanData.guarantee.address }}</span>
                                                    </li>
                                                    <li>
                                                        Educación: <span>{{ applicantLoanData.guarantee.education_level }}</span>
                                                    </li>
                                                    <li>
                                                        Experiencia personal o de negocios: <span>{{ applicantLoanData.guarantee.ocupation.name }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                            </div>
                        </vs-tab>
                    <!-- proyecto -->
                        <vs-tab label="Proyecto">
                            <div class="flex ml-3">
                                <feather-icon icon="TargetIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Sobre el proyecto <span style="color: #28DE18;">{{publicProjectData.project.alias}}</span></h3>
                            </div>
                            <div class="vx-row m-5">
                                <!-- resumen -->
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-2">
                                    <!-- <div class="flex">
                                        <div style="background-color: #28de18; width: 8rem; height: 8rem; margin-right: 1rem;">
                                                <feather-icon icon="BoxIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" /> <span>{{ financeLoanData.project.project_category ? financeLoanData.project.project_category.name : "No definido" }}</span>
                                            </div> 
                                            <div style="background-color: #28de18; width: 8rem; height: 8rem; margin-right: 1rem;">
                                                <feather-icon icon="BoxIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" /> <span>{{ financeLoanData.project.project_category ? translateStrategy(financeLoanData.project.project_category.strategy) : "No definido" }}</span>
                                            </div> 
                                    </div> -->
                                    <ul class="list-bold">
                                        <li>
                                            Proyecto: <span>{{publicProjectData.project.alias}}</span>
                                        </li>
                                        <li>
                                            Estrategia: <span>{{ financeLoanData.project.project_category ? translateStrategy(financeLoanData.project.project_category.strategy) : "No definido" }}</span>  
                                        </li>
                                        <li>
                                            Línea de acción: <span>{{ financeLoanData.project.project_category ? financeLoanData.project.project_category.name : "No definido" }}</span> 
                                        </li>
                                        <!-- <li class="mt-5">
                                            Uso general de los recursos: <span>{{ financeLoanData.loan_use_cases || "No definido" }}</span>
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2 mb-2">
                                    <div @click.stop="goToOdsBlog" class="flex">
                                        <p class="bold mb-3 mr-3">
                                            Objetos de Desarrollo Sostenible
                                        </p>
                                        <vx-tooltip
                                            title=""
                                            color="primary"
                                            style="float:right;cursor:pointer;margin-top:-8px"
                                            text="¿Qué son los Objetivos de Desarrollo Sostenible?">
                                            <vs-chip color="primary">?</vs-chip>
                                        </vx-tooltip>
                                    </div>
                                    <div class="flex">
                                        <img v-for="(ods, ODSIndex) in getOds.pre_set" :src="getCurrentOds(ods)" :alt="`ODS-${ods}`" :key="ODSIndex" class="fix-ods">
                                    </div>
                                </div>
                                <vs-divider></vs-divider>
                                <!-- información adicional -->
                                <div class="vx-col w-full">
                                    <vs-collapse v-if="isMounted" :open="true">
                                        <vs-collapse-item :open="true">
                                            <div class="black" slot="header">
                                                Uso de recursos
                                            </div>
                                            <ul>
                                                <li>
                                                    {{ financeLoanData.loan_use_cases || "No definido" }}
                                                </li>
                                                <!-- <li>
                                                    Uso específico de recursos: Fondo de pre-cosecha y acopio
                                                </li> -->
                                            </ul>
                                        </vs-collapse-item>
                                        <vs-collapse-item v-if="isMoral && financeLoanData.investment_opportunities" :open="true">
                                            <div class="black" slot="header">
                                                Puntos clave de la oportunidad
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ setBreakPoints(financeLoanData.investment_opportunities) || "NA"}}
                                            </div>
                                        </vs-collapse-item>
                                        <vs-collapse-item :open="true">
                                            <div class="black" slot="header">
                                                Descripción
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ financeLoanData.project.description }}
                                            </div>
                                        </vs-collapse-item>
                                    <!-- INFORMACIÓN SOBRE LA TECNOLOGÍA -->
                                        <vs-collapse-item v-if="financeLoanData.loan_type == 1" :open="true">
                                            <div class="black" slot="header">
                                                Tecnologías
                                            </div>
                                            <div v-for="pg_request in financeLoanData.pg_requests" class="vx-col w-full mb-base">

                                                 <ul class="list-bold mb-2">
                                                    <li>
                                                        {{ pg_request.family_group.name }}<span></span>
                                                    </li>
                                                </ul>
                                                
                                                <vs-table id="guarantee-table" :data="pg_request.pg_requests" stripe noDataText="No hay registros disponibles">
                                                    <template slot="thead">
                                                        <vs-th class="bold" v-for="(col, idx) in columnsTechnology" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                                    </template>
                
                                                    <template slot-scope="{data}">
                                                        <tbody>
                                                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                            <vs-td>
                                                                {{tr.product_model.product_brand.product_group.name}}
                                                            </vs-td>
                                                            <vs-td>
                                                                {{tr.product_model.product_brand.brand}}
                                                            </vs-td>
                                                            <vs-td>
                                                                {{tr.product_model.model}}
                                                            </vs-td>
                                                            <vs-td>
                                                                {{tr.product_model.capacity + tr.product_model.product_brand.product_group.capacity_units}}
                                                            </vs-td>
                                                            <vs-td>
                                                                {{tr.quantity}}
                                                            </vs-td>
                                                            
                                                        </vs-tr>
                                                        </tbody>
                                                    </template>
                                                </vs-table>
                                           </div>
                                        </vs-collapse-item>
                                    <!-- OTROS DOCUMENTOS -->
                                        <vs-collapse-item v-if="financeLoanData.project.pitch_file || applicantLoanData.institutional_video || financeLoanData.project.webinar || financeLoanData.related_contents.length > 0" :open="true">
                                            <div class="black" slot="header">
                                                Otros documentos
                                            </div>
                                            <div class="vx-row">
                                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
                                                    <ul class="content-list">
                                                        <li v-if="financeLoanData.project.pitch_file">
                                                            <p @click.stop="goToNewContent(financeLoanData.project.pitch_file)">Presentación ejecutiva</p>
                                                        </li>
                                                        <li v-if="applicantLoanData.institutional_video">
                                                            <p @click.stop="goToNewContent(applicantLoanData.institutional_video)">Video institucional</p>
                                                        </li>
                                                        <li v-if="financeLoanData.project.webinar">
                                                            <p @click.stop="goToNewContent(financeLoanData.project.webinar)">Webinar</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
                                                    <ul class="content-list">
                                                        <li v-for="content in financeLoanData.related_contents">
                                                            <p @click.stop="goToNewContent(content.link)">{{ content.name }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                            </div>
                        </vs-tab>
                    <!-- Proveedor -->
                        <vs-tab v-if="financeLoanData.loan_type == 1 && supplierInfo != null" label="Proveedor">
                        <!-- logo -->
                            <div class="flex ml-3">
                                <feather-icon icon="ToolIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Sobre el proveedor</h3>
                            </div>
                        <!-- información -->
                            <div class="vx-row m-5">
                                <!-- resumen -->
                                <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 mb-2">
                                    <div class="con-example-images mb-2">
                                        <img v-if="supplierLogoUrl != undefined" class="fix-logo-supplier" :src="supplierLogoUrl" alt="Logo_proveedor">
                                        <img v-else src="https://cdn.redgirasol.com/green/perfiles/hombre.jpg" class="fix-logo-supplier" alt="Logo_proveedor">
                                    </div>
                                </div>
                                <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-2">
                                    <ul class="list-bold mb-2">
                                        <li>
                                            {{publicProjectData.installer.tradename}}<span></span>
                                        </li>
                                        <li>
                                            <span>{{ publicProjectData.installer.extra_info }}</span>
                                        </li>
                                        <template v-if="hasSupplierDuePortafolio">
                                            <li>
                                                Proyectos fondeados: <span>{{ supplierInfo.duePortafolio[0].projects_funded_number }}</span>
                                            </li>
                                            <li>
                                                Proyectos vencidos: <span>{{ supplierInfo.duePortafolio[0].projects_funded_fall_due_number }}</span>
                                            </li>
                                            <li>
                                                Monto financiado: <span>{{ explicitMoneyFormat(supplierInfo.duePortafolio[0].projects_funded_amount) }}</span>
                                            </li>
                                        </template>
                                    </ul>
                                    <div class="flex">
                                        <a
                                            v-if="webpageUrl"
                                            :href="$sanitizeUrl(webpageUrl)"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <feather-icon icon="GlobeIcon" class=" text-dark" />
                                        </a>
                                        <a
                                            v-if="facebookUrl"
                                            :href="$sanitizeUrl(facebookUrl)"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <feather-icon icon="FacebookIcon" class="inline-social-icon text-dark" />
                                        </a>
                                        <a
                                            v-if="twitterUrl"
                                            :href="$sanitizeUrl(twitterUrl)"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <feather-icon icon="TwitterIcon" class="inline-social-icon text-dark" />
                                        </a>
                                        <a
                                            v-if="instagramUrl"
                                            :href="$sanitizeUrl(instagramUrl)"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <feather-icon icon="InstagramIcon" class="inline-social-icon text-dark" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </vs-tab>
                    <!-- analisis -->
                        <vs-tab v-if="isMoral && (applicantLoanData.financial_performance && applicantLoanData.financial_indicators)" label="Análisis financiero">
                            <div class="flex ml-3">
                                <feather-icon icon="DollarSignIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Análisis financiero</h3>
                            </div>
                            <div class="vx-row m-5">
                                <!-- resumen -->
                                <!-- <h5 class="dark-blue">Beneficio financiero:</h5>
                                <vs-divider></vs-divider> -->
                                <!-- información adicional -->
                                <div class="vx-col w-full">
                                    <vs-collapse>
                                        <vs-collapse-item :open="true">
                                            <div class="black" slot="header">
                                                Situación financiera
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ applicantLoanData.financial_performance || "No definido" }}
                                            </div>
                                        </vs-collapse-item>
                                        <vs-collapse-item :open="true">
                                            <div class="black" slot="header">
                                                Indicadores financieros
                                            </div>
                                            <div class="text-pre-wrap">
                                                {{ applicantLoanData.financial_indicators || "No definido" }}
                                            </div>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                            </div>

                        </vs-tab>
                    <!-- riesgos -->
                        <vs-tab v-if="financeLoanData.risk_factors != null && (isMoral || applicantLoanData.tax_profile == 2)" label="Factores de riesgo">
                            <div class="flex ml-3">
                                <feather-icon icon="AlertCircleIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Factores de riesgo</h3>
                            </div>
                            <div class="vx-col w-full ml-12">
                                <ul class="list-bold" v-if="tr.risk_factors.length > 1" v-for="(tr, index) in financeLoanData.risk_factors" :key="index">
                                    <li>
                                        {{ tr.category }} <br>
                                        <span class="ml-3" :key="rindex" v-for="(risk, rindex) in tr.risk_factors">{{ risk.text }} <br></span>
                                        <vs-divider></vs-divider>
                                    </li>
                                </ul>
                            </div>
                        </vs-tab>
                    <!-- garantías -->
                        <vs-tab label="Garantías">
                            <div class="flex ml-3">
                                <feather-icon icon="ShieldIcon" svgClasses="w-5 h-5 text-primary stroke-current bold" />
                                <h3 class="dark-blue m-3">Instrumentación y ejecución de garantías</h3>
                            </div>
                            <div class="vx-row m-5">
                                <div class="vx-col w-full">
                                    <vs-table id="guarantee-table" :data="financeLoanData.loan_guarantees" stripe noDataText="No hay registros disponibles">
                                        <template slot="thead">
                                            <vs-th class="bold text-center">TIPO</vs-th>
                                            <vs-th class="bold">GARANTÍA</vs-th>
                                            <vs-th class="bold">ESTRATEGIA DE RECUPERACIÓN</vs-th>
    
                                        </template>
    
                                        <template slot-scope="{data}">
                                            <tbody>
                                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                <vs-td>
                                                    {{translateGuaranteeType(tr.type)}}
                                                </vs-td>
                                                <vs-td>
                                                    {{tr.name}}
                                                </vs-td>
                                                <vs-td>
                                                    {{tr.recovery_strategy || "NA"}}
                                                </vs-td>
                                            </vs-tr>
                                            </tbody>
                                        </template>
                                    </vs-table>
                                </div>
                            </div>
                        </vs-tab>
                    </vs-tabs>
                </vx-card>
            </div>

            <!-- datos de impacto -->
            <!-- <div class="vx-col w-full mb-base align-center">
                <h3 class="dark-blue m-3 mb-base">Datos de impacto</h3>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 mb-base">
                        <vx-card no-shadow class="card-data">
                            <vs-avatar class="avatar-custom" color="transparent" size="50px" :src="figs.environment" />
                            <br>
                            <h5 class="dark-blue-70">Ambiental</h5>
                            <br>
                            <p class="dark-blue-70">Expert tips & tools to improve your website or online store using blog.</p>
                            <br>
                            <vs-button size="small" color="gray">Leer más</vs-button>
                        </vx-card>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 mb-base">
                        <vx-card no-shadow class="card-data">
                            <vs-avatar class="avatar-custom" color="transparent" size="50px" :src="figs.social"/>
                            <br>
                            <h5 class="dark-blue-70">Social</h5>
                            <br>
                            <p class="dark-blue-70">Expert tips & tools to improve your website or online store using blog.</p>
                            <br>
                            <vs-button size="small" color="gray">Leer más</vs-button>
                        </vx-card>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 mb-base">
                        <vx-card no-shadow class="card-data">
                            <vs-avatar class="avatar-custom" color="transparent" size="50px" :src="figs.money"/>
                            <br>
                            <h5 class="dark-blue-70">Económico</h5>
                            <br>
                            <p class="dark-blue-70">Expert tips & tools to improve your website or online store using blog.</p>
                            <br>
                            <vs-button size="small" color="gray">Leer más</vs-button>
                        </vx-card>
                    </div>
                </div>
            </div> -->

            <!-- reporte trimestral - HOLD -->
            <!-- <div class="vx-col w-full mb-base">
                <vx-card>
                    <h3 class="dark-blue m-3">Seguimiento</h3>
                    <ul class="list-bold m-5">
                        <li>
                            Reporte trimestral: <span></span>
                        </li>
                        <li>
                            Avance de pagos: <span></span>
                        </li>
                        <li>
                            <a href="#">Enlace a documentos</a>
                        </li>
                    </ul>
                </vx-card>
            </div> -->
        </div>

         <!-- POPUP Reembolso -->
        <vs-popup :active.sync="isRefundPopupActive" title="Reembolso de fondos." v-if="isRefundPopupActive" class="header-primary">
            <refund-project-dialog :project-data="publicProjectData" :project-id="id" @on-success="onRefundSuccess" />
        </vs-popup>
    </div>

</template>
  
<script>

const columns = [
    { title: "MES", key: "month", sortable: true }
    , { title: "FECHA DE PAGO", key: "payment_date", sortable: true }
    , { title: "PAGO", key: "payment", sortable: true }
    , { title: "SALDO INICIAL", key: "initial_debt", sortable: false }
    , { title: "AMORTIZACIÓN", key: "amortization", sortable: false }
    , { title: "INTERESES", key: "interest", sortable: false }
    , { title: "IVA", key: "tax", sortable: false }
    , { title: "SALDO FINAL", key: "final_debt", sortable: false }
];

const columnsTechnology = [
    { title: "PRODUCTO", key: "product", sortable: true }
    , { title: "MARCA", key: "brand", sortable: true }
    , { title: "MODELO", key: "model", sortable: true }
    , { title: "CAPACIDAD", key: "capacity", sortable: false }
    , { title: "CANTIDAD", key: "quantity", sortable: false }
];
    

const httpPattern = /^http[s]*:\/\/[\w]+/i;

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import VxViewUpload from "@upload/VxViewUpload";
import VueLoadImage from 'vue-load-image';
import formatHelper from "@mixins/formatHelper";
import dateHelper from "@mixins/dateHelper";
import YoutubeVideoWidget from '@components/supplier/widgets/YoutubeVideoWidget.vue';
import RefundProjectDialog from "@components/investor/invested/RefundProjectDialog";
export default {
    name: "loan-detail",
    props: {
        id: {
            type: [String,Number],
            required: true,
        },
        page: {
            type: String,
            required: false,
            default: ""

        },
    },
    mixins: [formatHelper, dateHelper],
    components: {
        Swiper,
        SwiperSlide,
        VxViewUpload,
        VueLoadImage,
        YoutubeVideoWidget,
        RefundProjectDialog
    },
    data() {
        return {
            columns: columns,
            columnsTechnology: columnsTechnology,
            alias: null,
            typeName: null,
            financeLoanData: null,
            applicantLoanData: null,
            publicProjectData: null,
            supplierInfo: null,
            isMounted: false,
            projectImages: [],
            activeTab: 0,
            investorData: {},
            projectLimitsData: {},
            successDialogActive: false,
            figs: {
                environment: require('@assets/images/investor-figs/leaf.svg'),
                social: require('@assets/images/investor-figs/social.svg'),
                money: require('@assets/images/investor-figs/money.svg')
            },
            loader: require('@assets/images/carousel/loader-green.gif'),
            placeholder: require('@assets/images/pages/placeholder-image.png'),
            swiperOptions: {
                slidesPerView: 5,
                spaceBetween: 30,
                centeredSlides: true,
                freeMode: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            ods01: require('@assets/images/ods/S-WEB-Goal-01.png'),
            ods02: require('@assets/images/ods/S-WEB-Goal-02.png'),
            ods03: require('@assets/images/ods/S-WEB-Goal-03.png'),
            ods04: require('@assets/images/ods/S-WEB-Goal-04.png'),
            ods05: require('@assets/images/ods/S-WEB-Goal-05.png'),
            ods06: require('@assets/images/ods/S-WEB-Goal-06.png'),
            ods07: require('@assets/images/ods/S-WEB-Goal-07.png'),
            ods08: require('@assets/images/ods/S-WEB-Goal-08.png'),
            ods09: require('@assets/images/ods/S-WEB-Goal-09.png'),
            ods10: require('@assets/images/ods/S-WEB-Goal-10.png'),
            ods11: require('@assets/images/ods/S-WEB-Goal-11.png'),
            ods12: require('@assets/images/ods/S-WEB-Goal-12.png'),
            ods13: require('@assets/images/ods/S-WEB-Goal-13.png'),
            ods14: require('@assets/images/ods/S-WEB-Goal-14.png'),
            ods15: require('@assets/images/ods/S-WEB-Goal-15.png'),
        }
    },
    async beforeMount() {
        this.isMounted = false
        this.showLoading(true);
        if(this.page !== "mis-proyectos"){
            await this.getProjectAvailability()
        }
        await this.getFinanceLoanData();
        await this.getApplicantLoanData();
        await this.getPublicProjectData();
        await this.getSupplierInfo();
        await this.getNewInvestmentData();
        if(this.isMoral) {
            this.getImages();
        }
        this.showLoading(false);
        this.isMounted = true
    },
    computed: {
        isRefundPopupActive: {
            get() {
                return this.$store.state.isRefundCardPopupActive;
            },
            set(val) {
                this.$store.commit("TOGGLE_IS_REFUND_POPUP_ACTIVE", val);
            }
        },
        canAskForRefund(){
            return this.publicProjectData.project.status === 5 && this.investorData.projectInvested > 0;
        },
        investedPercentage() {
            return (this.financeLoanData.invested *  100 ) / this.financeLoanData.financed_amount;
        },
        isMoral(){
            return !this.financeLoanData.person_type;
        },
        images(){
            if(this.projectImages.length < 1){
                return  [];
            }
            let imgs = [];
            this.projectImages.forEach(f => {
                let url = `https://cdn.redgirasol.com/projects/${this.id}/business_images/${f.storage_file}`;
                imgs.push({url: url, filename: f.storage_file});
            });
            return imgs;
        },
        getUrlLogo() {
            let url = "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/PORTADAS-07.png";
            if(this.isMoral){
                this.checkIfImageExists(`${this.ApiDomain}/storagev3/documents/business-profile/${this.id}/logo/view?access_token=${this.AccessToken}`, (exists) => {
                    if (exists) {
                        url = `${this.ApiDomain}/storagev3/documents/business-profile/${this.id}/logo/view?access_token=${this.AccessToken}`;
                    }
                });
            } 

            return url;
        },
        supplierLogoUrl(){
            if(this.publicProjectData.installer.logo_doc_file_url != null){
                return this.ApiDomain + this.publicProjectData.installer.logo_doc_file_url;
            }
            else {
                // return this.ApiDomain + '/logos/' + this.installer.logo_file;
                return undefined;
            }
        },
        facebookUrl(){
            return this.formatUrl(this.publicProjectData.installer.facebook);
        },
        webpageUrl(){
            return this.formatUrl(this.publicProjectData.installer.webpage);
        },
        twitterUrl(){
            return this.formatUrl(this.publicProjectData.installer.twitter);
        },
        instagramUrl(){
            return this.formatUrl(this.publicProjectData.installer.instagram);
        },
        videoStp() {
            if(this.isMoral){
                return { 
                    sources: [
                        { type: 'video/youtube', src: this.applicantLoanData.institutional_video }
                    ], 
                    poster: '' 
                }
            }

            return null;
        },
        webinarInfo() {
            if(this.isMoral){
                return { 
                    sources: [
                        { type: 'video/youtube', src: this.financeLoanData.project.webinar }
                    ], 
                    poster: this.getUrlLogo
                }
            }

            return null;
        },
        socialNetworks() {
            if(this.applicantLoanData.social_networks != null){
                return this.applicantLoanData.social_networks.split(",");
            }
            return [];
        },
        getPromoUrl()
        {
            if(this.financeLoanData.promo.promo_image_file !== null) {
                return "https://assets.playbusiness.mx/uploads/startup/profile_image/871/PERFIL-01.png";
                // return `https://cdn.redgirasol.com/projects/${this.id}/promo/${this.financeLoanData.promo.promo_image_file}`;
            }

            return null;
        },
        getOds() {
            if(this.financeLoanData.project.project_category){
                return JSON.parse(this.financeLoanData.project.project_category.ods);
            }

            return [];
        },
        hasSupplierDuePortafolio() {
            return this.supplierInfo != null ? (this.supplierInfo.duePortafolio != null && this.supplierInfo.duePortafolio.length > 0) : false;
        }
    },
    methods: {
        setBreakPoints(text)
        {
            return text.split("\\n").join("\n")
        },
        async getPublicProjectData(){
            try {
                const res = await axios.get(`api/projects/single/${this.id}`);
                this.publicProjectData = res.data;
            } catch (error) {
                this.warn(e);
            }
        },
        async getFinanceLoanData() {
            try {
                const res = await axios.get(`api/get/about-finance/${this.id}`);
                this.financeLoanData = res.data;
            }
            catch (e) {
                this.warn(e);
            }
        },
        async getApplicantLoanData() {
            try {
                const res = await axios.get(`api/get/about-applicant/${this.id}`);
                this.applicantLoanData = res.data;
            }
            catch (e) {
                this.warn(e);
            }
        },
        async getSupplierInfo(){
            this.showLoading(true)
            try {
                const res = await axios.get(`api/v2/investor/${this.InvestorId}/getProjectInstallerInfo/${this.id}`);
                this.supplierInfo = res.data.projectsInfo;

                // // general url
                // this.images.forEach(img => {
                // if(this.validateImageExtension(img.storage_file)){
                //     let url = `${this.ApiDomain}/storagev3/documents/view_document_file/${img.id}/project/${img.pid}?access_token=${this.AccessToken}`;
                //     this.carousel.push({index: img.id, url: url, filename: img.storage_file});
                // }
                // });
            }
            catch (e) {
            }
            this.showLoading(false)
        },
        translateLoanType(type) {
            const loanTypes = {
                1: "Préstamo personal"
                , 2: "Préstamo empresarial"
            };

            return loanTypes[type] || "NA";

        },
        translateGuaranteeType(type) {
            const loanTypes = {
                "real_real_state" : "Bien inmueble"
                , "real_property" : "Bien mueble"
                , "real_accounts_receivable" : "Cuentas por cobrar"
                , "personal_jointly_bound" : "Obligado Solidario"
                , "promissory_note" :	"Pagaré"
            };

            return loanTypes[type] || "NA";

        },
        translateFiscalBusinessType(type) {
            const loanTypes = {
                  "sacv" : "Sociedad Anónima de Capital Variable"
                , "sapi" : "Sociedad Anónima Promotora de Inversión"
                , "scoop" : "Sociedad Cooperativa"
                , "scivil" : "Sociedad Civil"
                , "ac" : "Asociación Civil"
                , "abp" : "Asociación de Beneficencia Privada"
                , "srlcv" : "Sociedad de Responsabilidad Limitada de Capital Variable"
                , "sprrlcv" : "Sociedad de Producción Rural de Responsabilidad Limitada de Capital Variable"
            };

            return loanTypes[type] || "No definido";

        },
        translateStrategy(type) {
            const loanTypes = {
                  "energy" : "Energía"
                , "conservation" : "Conservación"
                , "agriculture_and_land" : "Agricultura y tierra"
                , "industry" : "Industria"
                , "transportation" : "Transportación"
                , "other_energy" : "Otras energías"
                , "food_waste" : "Desechos alimentarios"
                , "buildings" : "Construcción"
            };

            return loanTypes[type] || "No definido";

        },
        async getImages() {
            if(this.applicantLoanData.business_profile_id) {
                try {
                const res = await axios.get(`/api/get/business-profile-images/${this.applicantLoanData.business_profile_id}`);
                this.projectImages = res.data;
                } catch (error) {
                    console.log(error);
                    this.showLoading(false);
                }
            }
        },
        goToOportunities(){
            this.$router.push({name: 'oportunidades'});
        },
        formatUrl(url){
            if(!url){
                return null;
            }

            if(!httpPattern.exec(url)){
                return "https://" + url;
            }
            else{
                return url;
            }
        },
        goToNewContent(url){
            const anchor = document.createElement('a');

            Object.assign(anchor, {
                target: '_blank',
                href: url,
                rel: 'noopener noreferrer'
            })
            .click()
        },
        calculateSocialNetwork(type){
            let socialNetwork = {
                url: "",
                icon: ""
            };
            for (let index = 0; index < this.socialNetworks.length; index++) {
                const element = this.socialNetworks[index].indexOf(type);
                if(element != -1){
                    socialNetwork.icon = type + "Icon";
                    socialNetwork.url = this.formatUrl(this.socialNetworks[index]);
                    break;
                }
            }
            return socialNetwork;
        },
        checkIfImageExists(url, callback) {
            const img = new Image();
            img.src = url;
            
            if (img.complete) {
                callback(true);
            } else {
                img.onload = () => {
                callback(true);
                };
                
                img.onerror = () => {
                callback(false);
                };
            }
        },
        getCurrentOds(ods)
        {
            const odsCatalog = {
                  "01" : this.ods01
                , "02" : this.ods02
                , "03" : this.ods03
                , "04" : this.ods04
                , "05" : this.ods05
                , "06" : this.ods06
                , "07" : this.ods07
                , "08" : this.ods08
                , "09" : this.ods09
                , "10" : this.ods10
                , "11" : this.ods11
                , "12" : this.ods12
                , "13" : this.ods13
                , "14" : this.ods14
                , "15" : this.ods15
            }

            return odsCatalog[ods] || "No definido";
        },
        async getProjectAvailability()
        {
            if(this.id) {
                const res = await axios.get(`/api/get/project-availability/${this.id}`);
                if (!res.data) {
                    this.goToOportunities();
                }
            }
        },
        async onRefundClick(){
            this.isRefundPopupActive = true;
        },
        async getNewInvestmentData(){
            try {
                const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getDataForProjectInvestment/${this.id}`);
                this.investorData = res.data.investorData;
                this.projectLimitsData = res.data.projectData;
            }
            catch (e) {
            }
        },
        async onRefundSuccess(){
            try{
                await this.getNewInvestmentData();
            } catch (e) {
            }
            this.showLoading(false);
        },
    }
}
</script>

<style>
.con-example-images {
    max-height: 500px;
    overflow: auto;
}

.fix-logo {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

    .fix-logo-supplier {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    .fix-logo-promo {
        /* object-fit: contain; */
        width: 100%;
        height: 100%;
    }

span.text-chip {
    /* color: #00cfe8; */
    font-weight: 800;
}

#card-tabs div.vx-card__body  {
    padding: .5rem 0 0 0 !important;
}

.list-bold {
    font-weight: bold;
    line-height: 2rem;
    color: black;
}

    .list-bold span {
        font-weight: normal;
    }

.guarantees-list {
    line-height: 18px;
    margin-left: 2rem;
    list-style: disc
}

.content-list {
    text-decoration: underline;
    margin-left: 3rem;
    list-style: disc
}

.card-data {
    border: 1px solid #DBDADE;
    border-radius: 6px;
    background: transparent;
}

#guarantee-table thead tr{
    background: #4B4B4B;
    color: white;
}

#promo-card div.vx-card__body {
    padding: 0 !important;
}

.avatar-custom div.vs-avatar--con-img {
    border-radius: 0;
}

.promo-padding {
    padding: 1.5rem;
}

.fix-ods {
    width: 7rem;
    height: 7rem;
    margin-right: 1rem;
}
.fix-ods-header {
    width: 7rem;
    height: 7rem;
    /* margin-right: 1rem; */
}
</style>