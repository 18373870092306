<template>
  <div class="p-4 pt-1">
    <div v-if="canRefund">
      <div v-if="onFormView">
        <h5 class="title-dark">¿Cuánto quieres reembolsar?</h5>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <p>
              Indica el monto que deseas reembolsar a tu saldo disponible considerando que la cantidad restante
              no podrá ser menor al monto mínimo de <span class="bold">{{explicitMoneyFormat(minInvestment, 0)}}</span>.
              Podrás retirar tu inversión en su totalidad si así lo deseas. <br><br>
              Es importante que consideres que tus inversiones sólo pueden reembolsarse durante el periodo de fondeo.
            </p>
          </div>
          <div class="vx-col w-full mt-3">
            <p>
              <span class="bold">El monto actual de tu inversión: </span>{{explicitMoneyFormat(currentInvestment, 0)}}<br>
            </p>
          </div>
        </div>
        <vs-alert v-if="onError" color="danger" class="mt-3 mb-3" style="height: auto">{{ errorMssg }}</vs-alert>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <label class="pl-1">Ingresa un monto:</label>
            <div class="flex items-center flex-grow justify-left mt-1">
              <vs-input
                class="investment-input"
                type="text"
                v-model.lazy="currentRefund"
                v-money="currentRefund !== null || currentRefund === 0 || currentRefund === '$0' ? money : null"
                name="currentRefund"
                v-validate="'required'"
                :min="1"
                autocomplete="off"
                :placeholder="refundPlaceholder" />
              <vs-button class="statement-btn ml-6" color="primary" @click="refundRequest" >Reembolsar</vs-button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="onConfirmationView">
        <h5 class="title-dark">Confirma la operación</h5>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <p>
              Confirma que deseas realizar un reembolso por la cantidad de
              <span class="bold">{{ currentRefund }}</span>
              para el proyecto
              <span class="bold">{{ alias }}</span>.
            </p>
          </div>
          <div class="vx-col w-full mt-6">
            <div class="flex items-center flex-grow justify-left mt-1">
              <vs-button color="primary" @click="doRefund" >Confirmar</vs-button>
              <vs-button class="ml-6" color="dark" @click="declineRefund" >Cancelar</vs-button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="onResultView">
        <div v-if="refundSuccess">
          <h5 class="title-dark">¡Listo!</h5>
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <p>
                Tus fondos han sido reembolsados exitosamente a tu saldo disponible. <br>
                Si tienes alguna duda contáctanos a través del chat de la plataforma.
              </p>
            </div>
            <div class="vx-col w-full mt-6">
              <div class="flex items-center flex-grow justify-left mt-1">
                <vs-button color="primary" @click="goToInvest" >Invertir</vs-button>
                <vs-button class="ml-6" type="border" @click="goToMovements" >Mis movimientos</vs-button>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <h5 class="title-dark">Operación no exitosa.</h5>
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <p>
                Ha ocurrido un error con la operación, por favor intenta más tarde o
                contáctanos a través del chat de la plataforma para obtener más información.
              </p>
            </div>
            <div class="vx-col w-full mt-6">
              <div class="flex items-center flex-grow justify-left mt-1">
                <vs-button color="dark" @click="close" >Cerrar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-else-if="initialized">
      <h5 class="title-dark">No es posible realizar un reembolso</h5>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <p>
            El monto de tu inversión actual no supera el monto mínimo a invertir en este proyecto,
            por lo que no es posible que se haga un reembolso.
          </p>
        </div>
      </div>
    </div>

    <div v-if="!initialized">
      <h5 class="title-dark">Cargando</h5>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <p>
            Recopilando datos para el reembolso...
          </p>
        </div>
      </div>
    </div>

    <!-- Componente para obtener la geolocalización -->
    <rg-geolocator ref="rgGeolocator" />
  </div>
</template>

<script>
import {VMoney} from 'v-money'
import formatHelper from '@mixins/formatHelper';
import currencyHelper from "@mixins/currencyHelper";
export default {
  name: "RefundProjectDialog",
  props: {
    projectId: { type: [String,Number], required: true },
    projectData: { type: Object, required: true },
  },
  mixins: [formatHelper, currencyHelper],
  directives: {money: VMoney},
  data(){
    return {
      refundInfo:{
        projectMinimalInvestment: 0,
        automaticInvested: 0,
        totalInvested: 0,
        canRefund: false,
        maxRefund: 0,
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },
      onError: false,
      errorMssg: null,
      currentRefund: null,
      refundPlaceholder: "$1",
      minRefund: 1,
      currentView: 0,
      refundSuccess: false,
      refundedAll: false,
      initialized: false,
    }
  },
  watch: {
    '$store.state.isRefundCardPopupActive': function (newv, oldv){
      if(newv === false){
        this.resetForm();
      }
    }
  },
  async mounted() {
    this.initialized = false;
    await this.getRefundInfo();
    this.initialized = true;
  },
  computed: {
    onFormView(){
      return this.currentView === 0;
    },
    onConfirmationView(){
      return this.currentView === 1;
    },
    onResultView(){
      return this.currentView === 2;
    },
    alias(){
      return this.projectData.project.alias;
    },
    canRefund(){
      return (this.refundInfo?.canRefund || this.refundedAll);
    },
    maxRefund(){
      return this.refundInfo.maxRefund;
    },
    currentInvestment(){
      return this.refundInfo.totalInvested;
    },
    minInvestment(){
      return this.refundInfo.projectMinimalInvestment;
    }
  },
  methods: {
    resetForm(){
      this.currentView = 0;
      this.currentRefund = null;
      this.refundSuccess = false;
      this.refundedAll = false;
    },
    async getRefundInfo(){
      this.showLoading(true)
      try {
        const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getMaxRefundInfoForProject/${this.projectId}`);
        this.refundInfo = res.data;
        //this.refundPlaceholder = `max ${this.explicitMoneyFormat(this.refundInfo.maxRefund, 0)}`;
      }
      catch (e) {
      }
      this.showLoading(false)
    },
    async refundRequest(){
      // se pide la geolocalizacion para la operacion
      this.$refs.rgGeolocator.askForGeolocation();
      this.onError = false;
      this.errorMssg = null;
      const newRefund = this.unMoneyFormat(this.currentRefund);
      const valid = this.validateRefundAmount(newRefund);
      if(!valid){
        this.onError = true;
        return;
      }
      this.currentView = 1;
    },
    async doRefund(){
      this.showLoading(true)
      this.refundSuccess = false;
      const newRefund = this.unMoneyFormat(this.currentRefund);
      try {
        let refund = {amount: newRefund, geolocation: this.CurrentGeolocationData};
        await axios.post(`/api/v2/investor/${this.InvestorId}/doRefundForProject/${this.projectId}`, refund);
        this.refundedAll = (newRefund === this.refundInfo.totalInvested);
        await this.getRefundInfo();
        await this.onInvestorGeneralDataUpdated();
        this.$emit('on-success');
        this.refundSuccess = true;
      }
      catch (e) {
        this.refundSuccess = false;
      }
      this.currentView = 2;
      this.showLoading(false)
    },
    async declineRefund(){
      this.currentView = 0;
    },
    close(){
      this.$store.commit("TOGGLE_IS_REFUND_POPUP_ACTIVE", false);
    },
    async finish(){
      this.close();
      await this.$router.push({name: 'proyectoInversionista'});
    },
    async goToInvest(){
      this.close();
      await this.$router.push({name: 'oportunidades'});
    },
    async goToMovements(){
      this.close();
      await this.$router.push({name: 'misMovimientosInversionista'});
    },
    validateRefundAmount(amount){
      if(amount === null){
        this.errorMssg = "Por favor ingresa una cantidad válida";
        return false;
      }
      else if(amount < this.minRefund || amount > this.refundInfo.totalInvested){
        this.errorMssg = "Por favor ingresa una cantidad válida";
        return false;
      }
      else {
        let remaining = this.refundInfo.totalInvested - amount;
        if(remaining === 0){
          return true;
        }
        else if(remaining < this.refundInfo.projectMinimalInvestment){
          this.errorMssg = "El monto restante en inversión no puede ser menor a " +
            this.explicitMoneyFormat(this.refundInfo.projectMinimalInvestment, 0) + ", o debes retirar la totalidad de lo invertido.";
          return false;
        }
      }
      return true;
    },
  }
}
</script>

<style scoped>

</style>